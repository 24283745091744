import { Box, Text } from 'rebass'
import styled from 'styled-components'

import { BoxText, Container } from '../../components/styled'

export const StyledContainer = styled(Container)``

export const StyledBoxText = styled(BoxText)`
  max-width: initial;
`

export const StyledGridContainer = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  > div {
    margin-bottom: ${props => props.theme.space[4]}px;
  }
  @media (min-width: ${props => props.theme.breakpoints[0]}) {
    > div {
      margin-bottom: initial;
    }
    /* Supports Grid */
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    grid-auto-rows: minmax(300px, auto);
    grid-gap: ${props => props.theme.space[5]}px;
  }
`

export const StyledTextUppercase = styled(Text)`
  text-transform: uppercase;
`
