import Img from 'gatsby-image'
import React from 'react'
import { Flex } from 'rebass'
import styled from 'styled-components'

import Layout from '../../components/Layout'
import { Container } from '../../components/styled'
import {
  StyledGridContainer,
  StyledBoxText,
  StyledTextUppercase,
} from './team.styled'

const Photo = styled(Img)`
  max-width: 400px;
  height: 100%;
  width: 100%;
`

export const TeamTemplate = ({ teamImage, teamDescription, team }) => (
  <Layout>
    <Container>
      <Flex
        flexDirection="column"
        // alignItems="center"
        justifyContent="center"
        style={{ flexFlow: 'wrap', gap: 64 }}
      >
        <Photo fluid={teamImage.fluid} backgroundColor="#eeeeee" />
        <StyledBoxText
          fontSize={1}
          mb={4}
          flex={1}
          minWidth={300}
          dangerouslySetInnerHTML={{
            __html: teamDescription.childMarkdownRemark.html,
          }}
        />
      </Flex>
      <StyledGridContainer mb={3}>
        {team.map(member => (
          <Flex
            key={member.id}
            flexDirection="column"
            justifyContent="space-between"
          >
            <div>
              <Img fluid={member.image.fluid} backgroundColor="#eeeeee" />
              <StyledBoxText
                fontSize={1}
                my={3}
                dangerouslySetInnerHTML={{
                  __html: member.description.childMarkdownRemark.html,
                }}
              />
            </div>
            <div>
              <StyledTextUppercase>{member.name}</StyledTextUppercase>
              <StyledTextUppercase fontSize={1}>
                {member.position}
              </StyledTextUppercase>
            </div>
          </Flex>
        ))}
      </StyledGridContainer>
    </Container>
  </Layout>
)
