import { graphql } from 'gatsby'
import React from 'react'
import SEO from '../components/SEO'
import { TeamTemplate } from '../templates/team'

const TeamPage = ({ data }) => {
  const { teamDescription, teamImage } = data.contentfulConfiguracionGeneral
  /*   const team = data.allContentfulMiembroDelEquipo.edges.map(
    member => member.node
  ) */
  const team = []
  const {
    metaTitle,
    metaDescription: { metaDescription },
  } = data.contentfulPaginas
  return (
    <React.Fragment>
      <SEO isPage title={metaTitle} description={metaDescription} />
      <TeamTemplate
        teamImage={teamImage}
        teamDescription={teamDescription}
        team={team}
      />
    </React.Fragment>
  )
}

export const query = graphql`
  query {
    contentfulConfiguracionGeneral {
      teamDescription {
        childMarkdownRemark {
          html
        }
      }
      teamImage {
        title
        fluid(maxWidth: 1060) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
    contentfulPaginas(slug: { eq: "nosotras" }) {
      metaTitle
      metaDescription {
        metaDescription
      }
    }
  }
`

export default TeamPage
